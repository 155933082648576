import styled from "styled-components";
import { CommonColor } from "../utils/Color";

export const TitleTextNotFound = styled.h1`
  font-size: 7em;
  font-weight: 800;
  color: ${CommonColor.secondary_color};
  font-family: "Montserrat";
  margin-top: 5px;
  margin-bottom: 5px;
`;
export const NotFoundSubText = styled.h3`
  font-size: 2em;
  font-weight: 500;
  color: ${CommonColor.white};
  font-family: "Montserrat";
`;
export const NotFoundContainer = styled.div`
  width: 100dvw;
  height: 100dvh;
  background-color: ${CommonColor.black};
`;
