import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import Header from "../../component/commonComponent/Header";
import { onTogglePreLoader } from "../../service/Redux/UserStore";
import { FlexContainer, MainContainer } from "../../style/common.style";
import {
  NotFoundContainer,
  NotFoundSubText,
  TitleTextNotFound,
} from "../../style/NotFound";
import { endLoader } from "../../utils/Functions";
export default function NotFoundPage() {
  const dispatch = useDispatch();
  useEffect(() => {
    const timeline = gsap.timeline({});
    ScrollTrigger.refresh();
    gsap.to(window, {
      scrollTo: 0,
    });
    endLoader(timeline);
    dispatch(onTogglePreLoader(false));
  }, []);
  return (
    <NotFoundContainer id="not-found-page">
      <Header />
      <FlexContainer
        height="100dvh"
        width="100dvw"
        jc="center"
        verticalAlign="center"
        flexDirection="column"
      >
        <TitleTextNotFound>404</TitleTextNotFound>
        <NotFoundSubText>
          The page you request for, is not available :(
        </NotFoundSubText>
      </FlexContainer>
    </NotFoundContainer>
  );
}
