import React from "react";
import {
  PrivacyBigText,
  PrivacyContainer,
  PrivacyHeader,
  PrivacyPara,
} from "../../style/privacy.style";
import { Div } from "../../style/common.style";
import { onChangeNav } from "../../service/Redux/UserStore";
import { path } from "../../utils/Path";
import { useDispatch } from "react-redux";

export default function PrivacySectionOne() {
  const dispatch = useDispatch();
  return (
    <PrivacyContainer>
      <PrivacyHeader id="privacy-header">Privacy Policy</PrivacyHeader>
      <Div id="privacy-text">
        <PrivacyPara>
          Prexel is committed to respecting privacy and complying with
          applicable data protection and privacy laws & regulations. This
          Privacy Policy details how Prexel collects and manages data during its
          operations and the rights an individual or an entity has in relation
          to their data. Additional information may be provided regarding this
          Privacy Policy via notices/supplements. In the event of a conflict
          between the Privacy Policy and the notices/supplements, the
          notices/supplements will supersede the Privacy Policy. Individuals or
          entities that are not part of Prexel but work with Prexel to provide
          products, services, or any other types of collaboration may have their
          own privacy policies. Prexel is not responsible for the privacy
          practices of others and recommends that you read their privacy notices
          carefully.
        </PrivacyPara>
        <PrivacyBigText>What Data does Prexel collect?</PrivacyBigText>
        <PrivacyPara>
          Prexel collects data directly as well as from other available sources
          to the extent relevant and permitted by applicable local law. Prexel
          only collects data that is necessary for the requirements of
          collecting such data and retains said data till it is necessary for
          the requirements of retaining said data.
        </PrivacyPara>
        <PrivacyBigText>What will Prexel do with the Data?</PrivacyBigText>
        <PrivacyPara>
          Prexel collects, maintains, uses, stores, and otherwise processes the
          data for the purpose of performing its operations related to its
          products/services. Additionally, data may be processed for other
          purposes after consent and in compliance with all applicable laws and
          regulations. Prexel will only retain data till it is necessary for its
          operations, and the data will be removed when it is no longer
          required.
        </PrivacyPara>
        <PrivacyBigText>How is Sensitive Information treated?</PrivacyBigText>
        <PrivacyPara>
          Prexel focuses on limiting the collection of sensitive information and
          shall only do so if there is a legal justification for collecting and
          processing it or with express consent from the owner of the sensitive
          information. Prexel recognizes the additional need to protect
          Sensitive Information. To ensure safety, all Sensitive Information is
          processed with strict compliance with applicable law and only
          accessible to a restricted number of individuals who have a clear and
          justified need to know such information.
        </PrivacyPara>
        <PrivacyBigText>Who has access to the data?</PrivacyBigText>
        <PrivacyPara>
          Prexel has defined appropriate accessibility to ensure that only
          individuals with the right authority and need may have access to this
          data. There are control measures in place limiting the extent to which
          the data is accessed so that only the necessary data for operations
          can be accessed, and the remaining data stays restricted.
        </PrivacyPara>
        <PrivacyBigText>
          What steps are taken to safeguard Personal Data?
        </PrivacyBigText>
        <PrivacyPara>
          Prexel prioritizes Privacy and Security, and to ensure successful
          implementation, there are well-defined policies and guidelines in
          place. Various activities are undertaken toward securing data, which
          include but are not limited to proactive and reactive risk management,
          security and privacy engineering, training, and assessments. Online
          security, physical security, risk of data loss, and other such risks
          are appropriately addressed while considering the sensitivity of data
          and the risks related to processing data.
        </PrivacyPara>
        <PrivacyBigText>SMS Opt-In</PrivacyBigText>
        <PrivacyPara>
          SMS opt-in or phone numbers for the purpose of SMS are not shared with
          any third parties or affiliate companies for marketing purposes
        </PrivacyPara>
        <PrivacyBigText>Data quality</PrivacyBigText>
        <PrivacyPara>
          Appropriate steps are taken to ensure the accuracy of the data we
          possess, and irrelevant data is deleted as they are identified. Prexel
          also uses public sources to verify data to the extent permitted or
          required by applicable law or consent.
        </PrivacyPara>

        <PrivacyBigText>Whom to contact?</PrivacyBigText>
        <PrivacyPara>
          For queries regarding data and our privacy policy please contact us at
          &nbsp;
          <span
            className="link"
            onClick={() => dispatch(onChangeNav(path.contact))}
          >
            Contact Us
          </span>
        </PrivacyPara>
      </Div>
    </PrivacyContainer>
  );
}
